import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {
	PageBlockTitle,
	PageBlockWrapper,
	PageContent,
	PageContentWrapper,
	PageHero,
	PageHeroHeader,
	PageHeroMap,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { Link } from "gatsby-plugin-intl";
import Map from "../components/Map";
import { Breadcrumb, CustomButton } from "../assets/styles/globalCommon";
import { connect } from "react-redux";
import PageHeader from "../components/Common/PageHeader";
import InfoCircles from "../components/Common/InfoCircles";
import { appRedirect } from "../redux/app/actions";
import Tools from "../classes/tools";
import l from "lodash";

const Resortscountry = ({ centros, appRedirect, pageContext }) => {
	const { pais } = pageContext;

	const [centrosOrdenadosPorArea, setCentrosOrdenadosPorArea] = useState([]);

	useEffect(() => {
		const centrosOrdenadosPorArea = l.sortBy(pais.centros, (centro) => {
			return centro.area;
		});
		setCentrosOrdenadosPorArea(centrosOrdenadosPorArea);
	}, [pais]);

	let ultimaArea = "";

	return (
		<Layout>
			<Seo title="Estaciones País" />
			<section>
				<PageHero>
					<PageHeroHeader>
						<div>
							<Link to="/" className="lineLink">
								<u>Volver a la home</u>
							</Link>
						</div>
					</PageHeroHeader>
					<PageHeroMap>
						<Map lat={40.4145} long={-3.7045} zoom={8} id="resortsMap" />
					</PageHeroMap>
				</PageHero>

				<PageHeader
					layout="dark"
					title={`<span class="emoji" role="img">${
						pais.country_code ? Tools.getFlagEmoji(pais.country_code) : ""
					}</span> ${pais.title}`}
				>
					<InfoCircles
						items={[
							{
								label: "Estaciones",
								value: pais.centros.length,
							},
							// {
							// 	label: "Zonas",
							// 	value: "6",
							// },
						]}
					/>
				</PageHeader>

				<PageContentWrapper>
					<PageContent width="1050px" paddingBottom="0">
						<PageTwoColWrapper>
							<div className="pageCol fullCol">
								<Breadcrumb>
									<Link to="/resortsmap">Estaciones</Link>
									<a>&gt;</a>
									<a>{pais.title}</a>
								</Breadcrumb>
							</div>

							<p>
								{pais.country_code &&
									Tools.getFlagEmoji(pais.country_code)}{" "}
								{pais.title}
							</p>

							<div className="pageCol fullCol">
								{centrosOrdenadosPorArea.map((centro, index) => {
									const mostrarArea = centro.area !== ultimaArea;
									ultimaArea = centro.area;
									return (
										<PageBlockWrapper key={index}>
											{mostrarArea && (
												<PageBlockTitle>
													<h3>{centro.area}</h3>
												</PageBlockTitle>
											)}
											<div>
												<Link to={`/resort/${centro.name}`}>
													{centro.title}
												</Link>
											</div>
											{/* <CentrosList
												layout="dark"
												items={c}
												itemsLoadingSuccess={true}
											/> */}
										</PageBlockWrapper>
									);
								})}
							</div>

							<div className="pageCol leftCol">
								<p>
									Si tu estación o zona favorita no se encuentra en
									nuestra actual cobertura, puedes sugerirla haciendo
									clic en el siguiente enlace.
								</p>
								<br />
								<CustomButton className="ghost withUnderline">
									<p>Solicitar alta nueva estación</p>
								</CustomButton>
								<br />
								<br />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		centros: state.centros.data,
	};
};

const mapDispatchToProps = {
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Resortscountry);
